import NavbarComponent from "./component/CommonComponent/NavBar/NavBar";
import DemoNavbarComponent from "./component/CommonComponent/NavBar/DemoNavBar";
// import Home from "./component/HomePage/Home";
// import About from "./component/AboutPage/About";
// import LeanSuite from "./component/WhatLeanSuit/LeanSuite";
// import Report from "./component/ReportPage/Report";
// import Blogs from "./component/BlogPage/Blogs";
// import Contact from "./component/ContactPage/Contact";
// import Blog from "./component/BlogPage/SingleBlog/Blog";
// import Attachment from "./component/AttachmentTool/Attachment";
// import Campaign from "./component/Campaign/Campaign";
// import SuggestionPage from "./component/SuggestionPlatform/SuggestionPage";
// import PointsLeader from "./component/PointsLeaderboard/PointsLeader";
// import SuggestionOperations from "./component/SuggestionOperations/SuggestionOperation";
// import LMS from "./component/LMSPage/LMS";
// import RolesandPermissions from "./component/RolesPermissionsPage/RolesandPermissions";
// import TemplateBuilder from "./component/TemplateBuilder/TemplateBuilder";
// import Partners from "./component/PartnersPage/Partners";
// import QAMatrix from "./component/QAMatrixPage/QAMatrix";
// import Demo from "./component/DemoPage/Demo";
// import StepForm from "./component/StepsForm/StepForm";
// import Category from "./component/BlogPage/CategoryPage/Category";
// import Careers from "./component/CareersPage/Careers";
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from "react-router-dom";
// import PageNotFound from "./component/PageNotFound/PageNotFound";
// import ContinuousImprovement from "./component/ContinuousImprovementPage/ContinuousImprovement";
// import Press from "./component/PressPage/Press";
// import IssueTracker from "./component/Issuetracker/IssueTracker";
// import OperationMaintenance from "./component/OperationMaintenance/OperationMaintenance";
// import ManagementQuality from "./component/ManagementQuality/ManagementQuality";
// import Rca from "./component/RCA/Rca";
// import Competency from "./component/Competency/Competency";
// import Price from "./component/Pricing/Price";
// import SafetyImprovement from "./component/SafetyImprovementPage/SafetyImprovement";
// import QualityImprovement from "./component/QualityImprovement/QualityImprovement";
// import OperationalExcellence from "./component/OperationalExcellence/OperationalExcellence";
// import CostReduction from "./component/CostReduction/CostReduction";
// import WorkforceEnablement from "./component/WorkforceEnablement/WorkforceEnablement";
// import Resources from "./component/Resources/Resources";
// import Resource from "./component/Resources/SingleResource/Resource";
// import Legal from "./component/LegalPages/Legal";
// import DemoScheduler from "./component/DemoPage/DemoScheduler";
// import GembaWalker from "./component/GembaWalker/GembaWalker";
// import CilrManagement from "./component/CilrManagement/CilrManagement";
// import CustomizableFields from "./component/CustomizableFields/CustomizableFields";
// import MarketPlace from "./component/MarketPlace/MarketPlace";
// import SignUp from "./component/SignUp/SignUp";
// import JobCoverMatrix from "./component/JobCoverMatrix/JobCoverMatrix";
// import ViewAllOpportunities from "./component/ViewAllOpportunities/ViewAllOpportunities";
// import LossandCost from "./component/LossandCost/LossandCost";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import ResourceLanding from "./component/Resources/ResourceLandingPage/ResourceLanding";
// import ResourceForm from "./component/Resources/ResourceForm/ResourceForm";
// import ResourceThankyou from "./component/Resources/ResourceThankyou/ResourceThankyou";
import { Suspense, lazy, useEffect, useState } from "react";
import Loader from "./utils/Loader";
import AISuggestion from "./component/AISuggestion/AISuggestion";
import AIQAmatrix from "./component/AIQAmatrix/AIQAmatrix";
import VideoLanding from "./component/Resources/VideoLandingPage/VideoLanding";
import ModalComponent from "./component/CommonComponent/ModalComponent";

const Home = lazy(() => import("./component/HomePage/Home"))
const About = lazy(() => import("./component/AboutPage/About"))
const LeanSuite = lazy(() => import("./component/WhatLeanSuit/LeanSuite"))
const Report = lazy(() => import("./component/ReportPage/Report"))
const Blogs = lazy(() => import("./component/BlogPage/Blogs"))
const Contact = lazy(() => import("./component/ContactPage/Contact"))
const Attachment = lazy(() => import("./component/AttachmentTool/Attachment"))
const Campaign = lazy(() => import("./component/Campaign/Campaign"))
const SuggestionPage = lazy(() => import("./component/SuggestionPlatform/SuggestionPage"))
const PointsLeader = lazy(() => import("./component/PointsLeaderboard/PointsLeader"))
const SuggestionOperations = lazy(() => import("./component/SuggestionOperations/SuggestionOperation"))
const LMS = lazy(() => import("./component/LMSPage/LMS"))
const RolesandPermissions = lazy(() => import("./component/RolesPermissionsPage/RolesandPermissions"))
const TemplateBuilder = lazy(() => import("./component/TemplateBuilder/TemplateBuilder"))
const Partners = lazy(() => import("./component/PartnersPage/Partners"))
const QAMatrix = lazy(() => import("./component/QAMatrixPage/QAMatrix"))
const Demo = lazy(() => import("./component/DemoPage/Demo"))
const StepForm = lazy(() => import("./component/StepsForm/StepForm"))
const Category = lazy(() => import("./component/BlogPage/CategoryPage/Category"))
const Careers = lazy(() => import("./component/CareersPage/Careers"))
const Blog = lazy(() => import("./component/BlogPage/SingleBlog/Blog"))
const PageNotFound = lazy(() => import("./component/PageNotFound/PageNotFound"))
const ContinuousImprovement = lazy(() => import("./component/ContinuousImprovementPage/ContinuousImprovement"))
const Press = lazy(() => import("./component/PressPage/Press"))
const IssueTracker = lazy(() => import("./component/Issuetracker/IssueTracker"))
const OperationMaintenance = lazy(() => import("./component/OperationMaintenance/OperationMaintenance"))
const ManagementQuality = lazy(() => import("./component/ManagementQuality/ManagementQuality"))
const Rca = lazy(() => import("./component/RCA/Rca"))
const Competency = lazy(() => import("./component/Competency/Competency"))
const Price = lazy(() => import("./component/Pricing/Price"))
const SafetyImprovement = lazy(() => import("./component/SafetyImprovementPage/SafetyImprovement"))
const QualityImprovement = lazy(() => import("./component/QualityImprovement/QualityImprovement"))
const OperationalExcellence = lazy(() => import("./component/OperationalExcellence/OperationalExcellence"))
const CostReduction = lazy(() => import("./component/CostReduction/CostReduction"))
const WorkforceEnablement = lazy(() => import("./component/WorkforceEnablement/WorkforceEnablement"))
const Resources = lazy(() => import("./component/Resources/Resources"))
const Resource = lazy(() => import("./component/Resources/SingleResource/Resource"))
const Legal = lazy(() => import("./component/LegalPages/Legal"))
const DemoScheduler = lazy(() => import("./component/DemoPage/DemoScheduler"))
const GembaWalker = lazy(() => import("./component/GembaWalker/GembaWalker"))
const CilrManagement = lazy(() => import("./component/CilrManagement/CilrManagement"))
const CustomizableFields = lazy(() => import("./component/CustomizableFields/CustomizableFields"))
const MarketPlace = lazy(() => import("./component/MarketPlace/MarketPlace"))
const SignUp = lazy(() => import("./component/SignUp/SignUp"))
const JobCoverMatrix = lazy(() => import("./component/JobCoverMatrix/JobCoverMatrix"))
const ViewAllOpportunities = lazy(() => import("./component/ViewAllOpportunities/ViewAllOpportunities"))
const LossandCost = lazy(() => import("./component/LossandCost/LossandCost"))
const ResourceLanding = lazy(() => import("./component/Resources/ResourceLandingPage/ResourceLanding"))
const ResourceForm = lazy(() => import("./component/Resources/ResourceForm/ResourceForm"))
const ResourceThankyou = lazy(() => import("./component/Resources/ResourceThankyou/ResourceThankyou"))
const DemoPopup = lazy(() => import("./component/DemoPage/DemoPopup"))

function App() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Check if the modal has already been shown
    const modalShown = localStorage.getItem('modalShown');
    const modalTimestamp = localStorage.getItem('modalTimestamp');

    const currentTime = new Date().getTime();
    const modalShownBefore = modalShown === 'true';
    const modalRecentlyShown = modalTimestamp && (currentTime - parseInt(modalTimestamp, 10) < 12 * 60 * 60 * 1000);

    if (!modalShownBefore || !modalRecentlyShown) {
      // Show modal after 60 seconds if it hasn't been shown before or it's been more than 7 days
      const timer = setTimeout(() => {
        setShowModal(true);
        localStorage.setItem('modalShown', 'true'); // Set the flag in localStorage
        localStorage.setItem('modalTimestamp', currentTime.toString()); // Store the timestamp
      }, 60000); // 60 seconds

      return () => clearTimeout(timer); // Clean up on component unmount
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <Router>
        {/* <NavbarComponent /> */}
        <NavbarWithConditionalRendering />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/demo' component={DemoPopup} />
            <Route exact path='/aboutus' component={About} />
            <Route exact path='/what-is-leansuite' component={LeanSuite} />
            <Route exact path='/reports' component={Report} />
            <Route exact path='/blog' component={Blogs} />
            {/* <Route exact path="/category/:id" component={Category} /> */}
            <Route exact path='/contact-us' component={Contact} />
            <Route exact path='/attachment-tool' component={Attachment} />
            <Route exact path='/campaign' component={Campaign} />
            <Route exact path='/qa-matrix' component={QAMatrix} />
            <Route exact path='/schedule-demo' component={Demo} />
            <Route exact path='/careers' component={Careers} />
            <Route exact path='/learning-management-system' component={LMS} />
            <Route exact path='/suggestion-platform-for-hr' component={SuggestionPage} />
            <Route exact path='/category' component={Category} />
            <Route exact path='/category/:id/' component={Category} />
            {/* <Route exact path='/resources' component={Resources} /> */}
            <Route exact path='/resources/:resourceSlug/' component={Resources} />
            <Route path="/resources" render={() => <Redirect to="/resources/all" />} />
            <Route exact path='/resource/:id/' component={Resource} />
            <Route
              exact
              path='/suggestion-platform-for-operations'
              component={SuggestionOperations}
            />
            <Route
              exact
              path='/points-and-leaderboard'
              component={PointsLeader}
            />
            <Route
              exact
              path='/roles-and-permissions'
              component={RolesandPermissions}
            />
            <Route exact path='/template-builder' component={TemplateBuilder} />
            <Route
              exact
              path='/theleansuite-partner-community'
              component={Partners}
            />
            <Route
              exact
              path='/continuous-improvement-for-operations'
              component={ContinuousImprovement}
            />
            <Route exact path='/press' component={Press} />
            <Route path='/issue-tracker-for-safety' component={IssueTracker} />
            <Route
              path='/issue-tracker-for-operations'
              component={OperationMaintenance}
            />
            <Route
              path='/issue-tracker-for-quality'
              component={ManagementQuality}
            />
            <Route path='/rca' component={Rca} />
            <Route path='/competency-management' component={Competency} />
            <Route exact path='/pricing' component={Price} />
            <Route
              exact
              path='/safety-improvement'
              component={SafetyImprovement}
            />
            <Route
              exact
              path='/quality-improvement'
              component={QualityImprovement}
            />
            <Route
              exact
              path='/operational-excellence'
              component={OperationalExcellence}
            />
            <Route exact path='/cost-reduction' component={CostReduction} />
            <Route
              exact
              path='/workforce-enablement'
              component={WorkforceEnablement}
            />
            <Route exact path='/get-started-first-step' component={StepForm} />
            <Route path="/legal/:tab" component={Legal} />
            {/* Redirect to /legal/privacy if /legal is accessed */}
            <Route path="/legal" render={() => <Redirect to="/legal/privacy" />} />
            <Route exact path="/thank-you" component={DemoScheduler} />
            <Route exact path="/gemba-walker" component={GembaWalker} />
            <Route exact path="/cilr-management" component={CilrManagement} />
            <Route exact path="/customizable-forms" component={CustomizableFields} />
            <Route exact path="/marketplace" component={MarketPlace} />
            <Route exact path="/partner-sign-up" component={SignUp} />
            <Route exact path="/job-cover-matrix" component={JobCoverMatrix} />
            <Route exact path="/view-all-opportunities" component={ViewAllOpportunities} />
            <Route exact path="/loss-and-cost-management" component={LossandCost} />
            <Route exact path="/webinar-landing" component={ResourceLanding} />
            <Route exact path="/webinar-form" component={ResourceForm} />
            <Route exact path="/webinar-thanks" component={ResourceThankyou} />
            <Route exact path="/ai-suggestion" component={AISuggestion} />
            <Route exact path="/ai-qa-matrix" component={AIQAmatrix} />
            <Route exact path="/video-landing" component={VideoLanding} />
            <Route exact path='/:id/' component={Blog} />
            <Route path='*' component={PageNotFound} />
          </Switch>
        </Suspense>
      </Router>
      {/* Render the modal */}
      {/* <ModalComponent show={showModal} handleClose={handleCloseModal} /> */}
    </div>
  );
}

function NavbarWithConditionalRendering() {
  const location = useLocation();
  // List of routes where NavbarComponent should be hidden
  const hideNavbarRoutes = ['/get-started-first-step'];
  // List of routes where DemoNavbarComponent should be shown
  const showDemoNavbarRoutes = ['/schedule-demo', '/video-landing'];
  // Check if current location matches any route that should hide NavbarComponent
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);
  // Check if current location matches any route that should show DemoNavbarComponent
  const shouldShowDemoNavbar = showDemoNavbarRoutes.includes(location.pathname);
  return (
    <>
      {shouldHideNavbar ? null : (shouldShowDemoNavbar ? <DemoNavbarComponent /> : <NavbarComponent />)}
    </>
  );
}

export default App;
